import React from "react"
import { Wide } from "../components/layout"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Thanks = ({data, location}) => {
  return (
    <Layout>
      <SEO title="Steun Vonk" path={location.pathname} />
      <div className="bg-red-600">
        <div className="max-w-3xl mx-auto py-16 px-4 sm:py-24">
          <h2 className="text-4xl font-sans font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">Welkom bij Vonk+</h2>
          <p className="mt-5 text-2xl text-red-200">Bedankt dat je Vonk hebt gesteund</p>
        </div>
      </div>
      <Wide>
        <div className="max-w-3xl m-auto px-4">
          <p className="pb-4">Als het goed is ontvang je binnenkort een mail met details over je inschrijving en kort daarna je eerste Vonk Magazine ebook.</p>
          <p className="pb-4">Mocht je niet binnen een paar dagen van ons gehoord hebben, stuur dan een mailtje naar tom@vonkfantasy.nl, dan zorgen we ervoor dat het goed komt.</p>
          <p className="pb-4">Ook als je andere vragen hebt, stuur gerust een mailtje. Hopelijk zien we je binnenkort in de Vonk+ Discord, wat ook een prima plek is voor vragen.</p>
        </div>
      </Wide>
    </Layout>
  )
}

export default Thanks;